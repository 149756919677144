import { bindable, inject }           from 'aurelia-framework';
import { AnswerSealingRequestDialog } from 'resources/elements/html-elements/dialogs/answer-sealing-request-dialog';
import { AppContainer }               from 'resources/services/app-container';
import { BaseFormViewModel }          from 'base-form-view-model';
import { DialogService }              from 'aurelia-dialog';
import { FormSchema }                 from 'modules/sealings/requests/edit/form-schema';
import { SealingRequestsRepository }  from 'modules/sealings/requests/services/repository';

@inject(AppContainer, DialogService, FormSchema, SealingRequestsRepository)
export class EditSealingRequest extends BaseFormViewModel {

    @bindable headerTitle = 'form.title.answer-sealing-request';
    @bindable formId      = 'sealings-requests-edition-form';

    alert  = {};
    model  = {};
    schema = {};

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogService
     * @param formSchema
     * @param repository
     */
    constructor(appContainer, dialogService, formSchema, repository) {
        super(appContainer);

        this.dialogService = dialogService;
        this.formSchema    = formSchema;
        this.repository    = repository;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'sealings.requests.manage',
            'sealings.requests.edit',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params, navigationInstruction) {
        super.activate(navigationInstruction);

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.fetchData(params).then(() => this.schema = this.formSchema.schema(this));
    }

    /**
     * Handles attached event
     */
    attached() {
        super.attached();

        this.headerSubtitle = this.model.code;
    }

    /**
     * Fetches data from server
     *
     * @param {id}
     *
     * @returns {Promise.<*>|Promise}
     */
    fetchData({ id }) {
        return this.repository.find(id).then((response) => {
            this.initialModel.assign(response);
            this.model.assign(response);
        });
    }

    /**
     * Submits view form
     */
    submit() {
        this.alert = null;

        this.dialogService
            .open({ viewModel: AnswerSealingRequestDialog })
            .whenClosed(response => {
                if ( ! response.wasCancelled) {
                    return this.repository
                        .update(this.model.id, this.model)
                        .then(httpResponse => this.afterSubmitCallback(httpResponse))
                        .then(httpResponse => httpResponse.status === true ? this.appContainer.router.navigateToRoute('sealings.requests.index') : false);
                }
            });

        return false;
    }

    /**
     * Handles the submit form response
     *
     * @param response
     */
    afterSubmitCallback(response) {
        if (response.status === true) {
            this.appContainer.notifier.successNotice(response.message);

            this.initialModel.assign(this.model);
        } else {
            this.alert = this.alertMessage(response.status, response.message, response.errors);
        }

        return response;
    }

}
