import { bindable, inject }         from 'aurelia-framework';
import { AppContainer }             from 'resources/services/app-container';
import { BaseListViewModel }        from 'base-list-view-model';
import { DialogService }            from 'aurelia-dialog';
import { Downloader }               from 'resources/services/downloader';
import { FilterFormSchema }         from 'modules/sealings/results/filter-form-schema';
import { SealingResultsRepository } from 'modules/sealings/results/services/repository';

@inject(AppContainer, DialogService, Downloader, FilterFormSchema, SealingResultsRepository)
export class ListSealingResults extends BaseListViewModel {

    listingId = 'sealings-results-listing';

    @bindable headerTitle = 'listing.sealings.results';
    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogService
     * @param downloader
     * @param filterFormSchema
     * @param repository
     */
    constructor(appContainer, dialogService, downloader, filterFormSchema, repository) {
        super(appContainer);

        this.dialogService    = dialogService;
        this.downloader       = downloader;
        this.filterFormSchema = filterFormSchema;
        this.repository       = repository;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'sealings.results.manage',
            'sealings.results.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.filterModel  = this.filterFormSchema.model(this);
        this.filterSchema = this.filterFormSchema.schema(this);

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            resource:        'resource.sealings.sealing',
            repository:      this.repository,
            show:            null,
            edit:            null,
            destroy:         null,
            destroySelected: false,
            actions:         [
                {
                    icon:    'icon-file-pdf',
                    tooltip: 'form.button.download-report',
                    action:  (row) => this.downloadReport(row),
                    visible: (row) => row.report_id !== null,
                },
            ],
            options:         [
                {
                    label:  'form.button.export-to-excel',
                    icon:   'icon-file-excel',
                    action: () => this.exportListing('selagens'),
                },
            ],
            buttons:         [],
            selectable:      false,
            sorting:         {
                column:    6,
                direction: 'desc',
            },
            columns:         [
                {
                    data:   'code',
                    name:   'lifts.code',
                    title:  'form.field.lift-number',
                    type:   'link',
                    action: (row) => this.appContainer.router.navigateToRoute('lifts.lifts.view', { id: row.lift_id }),
                },
                {
                    data:  'address',
                    name:  'lifts.address',
                    title: 'form.field.address',
                },
                {
                    data:  'parish',
                    name:  'parishes.name',
                    title: 'form.field.parish',
                },
                {
                    data:  'maintenance_company',
                    name:  'maintenance_company_data.name',
                    title: 'form.field.emie',
                },
                {
                    data:  'owner',
                    name:  'owner_data.name',
                    title: 'form.field.owner',
                },
                {
                    data:  'inspecting_entity',
                    name:  'inspecting_entity_data.name',
                    title: 'form.field.eiie',
                },
                {
                    data:            'execution_date',
                    name:            'procedures.execution_date',
                    title:           'form.field.sealing-date',
                    valueConverters: [
                        { name: 'dateFormat' },
                    ],
                },
            ],
        };
    }

    /**
     * Downloads the given report
     *
     * @param row
     */
    downloadReport(row) {
        this.repository.report(row.id).then(result => this.downloader.download(result, 'relatorio', 'pdf'));
    }

}
