import { inject }                       from 'aurelia-framework';
import { ProcedureResponse }            from 'modules/procedures/models/procedure-response';
import { ProcedureResponsesRepository } from 'modules/procedures/responses/services/repository';
import moment                           from 'moment';

@inject(ProcedureResponsesRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        response_date: moment().format('YYYY-MM-DD'),
    };

    /**
     * Returns a new instance of the model
     *
     * @returns {ProcedureResponse}
     */
    model() {
        let model = new ProcedureResponse();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Constructor
     *
     * @param procedureResponsesRepository
     */
    constructor(procedureResponsesRepository) {
        this.procedureResponsesRepository = procedureResponsesRepository;
    }

    /**
     * Returns the form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.response_id = {
            type:         'select2',
            key:          'response_id',
            label:        'form.field.response',
            size:         4,
            options:      [],
            remoteSource: this.procedureResponsesRepository.active.bind(this.procedureResponsesRepository),
        };

        this.response_date = {
            type:            'material-ui-date-picker',
            key:             'response_date',
            label:           'form.field.response-date',
            size:            4,
            valueConverters: [
                { name: 'dateFormat' },
            ],
        };

        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('sealings.requests.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.answer-request',
            action:     () => viewModel.submit(),
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                right:      true,
                attributes: {
                    class: 'icon-chevron-right',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.submitButton,
            ],
        };

        return [
            [this.response_id, this.response_date],
            [this.buttons],
        ];
    }

}
