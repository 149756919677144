import { bindable, inject }            from 'aurelia-framework';
import { AppContainer }                from 'resources/services/app-container';
import { BaseFormViewModel }           from 'base-form-view-model';
import { ConfirmSealingRequestDialog } from 'resources/elements/html-elements/dialogs/confirm-sealing-request-dialog';
import { DialogService }               from 'aurelia-dialog';
import { SealingRequestsRepository }   from 'modules/sealings/requests/services/repository';
import { FormSchema }                  from 'modules/sealings/requests/create/form-schema';
import { InfoDialog }                  from 'resources/elements/html-elements/dialogs/info-dialog';

@inject(AppContainer, DialogService, FormSchema, SealingRequestsRepository)
export class CreateSealingRequest extends BaseFormViewModel {

    @bindable headerTitle = 'form.title.create-new-record';
    @bindable formId      = 'sealings.requests.creation-form';

    alert  = {};
    model  = {};
    schema = {};

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogService
     * @param formSchema
     * @param repository
     */
    constructor(appContainer, dialogService, formSchema, repository) {
        super(appContainer);

        this.dialogService = dialogService;
        this.formSchema    = formSchema;
        this.repository    = repository;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultFormView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'sealings.requests.manage',
            'sealings.requests.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.fetchData().then(() => this.schema = this.formSchema.schema(this));
    }

    /**
     * Submits view form
     */
    submit() {
        this.alert = null;

        return this.checkSelectedLifts() && this.confirmAndSubmit();
    }

    /**
     * Asks the user for confirmation to submit the request
     */
    confirmAndSubmit() {
        this.dialogService
            .open({
                viewModel: ConfirmSealingRequestDialog,
                model:     {
                    resource: 'resource.sealings.sealing',
                    records:  this.parseSelectedLifts(),
                    action:   {
                        method:     this.repository.create.bind(this.repository),
                        parameters: [this.model],
                    },
                },
            })
            .whenClosed(response => this.afterSubmitCallback(response));
    }

    /**
     * Handles the submit form response
     *
     * @param response
     */
    afterSubmitCallback(response) {
        if ( ! response.wasCancelled) {
            if (response.output.status === true) {
                this.initialModel.assign(this.model);

                this.appContainer.router.navigateToRoute('sealings.requests.index');
            } else {
                this.alert = this.alertMessage(response.output.status, response.output.message, response.output.errors);
            }
        }
    }

    /**
     * Checks if any lift was selected, displaying a warning otherwise
     *
     * @returns {bool}
     */
    checkSelectedLifts() {
        if (this.model.lifts.length === 0) {
            this.dialogService.open({
                viewModel: InfoDialog,
                model:     {
                    body:  this.appContainer.i18n.tr('message.select-at-least-one-record'),
                    title: this.appContainer.i18n.tr('text.attention'),
                },
            });
        }

        return this.model.lifts.length > 0;
    }

    /**
     * Parses the selected lift's codes
     *
     * @returns {string}
     */
    parseSelectedLifts() {
        return this.formSchema.lifts.options
            .filter(lift => this.model.lifts.includes(lift.id))
            .map(lift => lift.code)
            .join('<br />');
    }

}
