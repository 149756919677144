import { inject }           from 'aurelia-framework';
import { LiftsRepository }  from 'modules/lifts/lifts/services/repository';
import { ProcedureRequest } from 'modules/procedures/models/procedure-request';

@inject(LiftsRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {};

    /**
     * Constructor
     *
     * @param liftsRepository
     */
    constructor(liftsRepository) {
        this.liftsRepository = liftsRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {ProcedureRequest}
     */
    model() {
        let model = new ProcedureRequest();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {[*]}
     */
    schema(viewModel) {
        this.lifts = {
            type:           'multiselect-native',
            key:            'lifts',
            label:          'form.field.select-the-lift(s)-to-add',
            size:           12,
            options:        [],
            remoteSource:   this.liftsRepository.all.bind(this.liftsRepository),
            processResults: (row) => {
                row.name = `${row.code} - ${row.address}`;

                return row;
            },
        };

        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('sealings.requests.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.register-request',
            action:     () => viewModel.submit(),
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                right:      true,
                attributes: {
                    class: 'icon-chevron-right',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.submitButton,
            ],
        };

        return [
            [this.lifts],
            [this.buttons],
        ];
    }

}
