import { bindable, inject }                        from 'aurelia-framework';
import { AppContainer }                            from 'resources/services/app-container';
import { BaseListViewModel }                       from 'base-list-view-model';
import { ConfirmSealingRequestCancellationDialog } from 'resources/elements/html-elements/dialogs/confirm-sealing-request-cancellation-dialog';
import { DialogService }                           from 'aurelia-dialog';
import { Downloader }                              from 'resources/services/downloader';
import { FilterFormSchema }                        from 'modules/sealings/requests/filter-form-schema';
import { SealingRequestsRepository }               from 'modules/sealings/requests/services/repository';
import moment                                      from 'moment';

@inject(AppContainer, DialogService, Downloader, FilterFormSchema, SealingRequestsRepository)
export class ListSealingRequests extends BaseListViewModel {

    listingId = 'sealings-requests-listing';

    @bindable headerTitle    = 'listing.sealings.requests';
    @bindable newRecordRoute = 'sealings.requests.create';
    @bindable repository;
    @bindable datatable;
    @bindable legend = [
        { color: 'success', name: 'messages.on-time' },
        { color: 'warning', name: 'messages.less-than-one-month-remaining' },
        { color: 'danger', name: 'messages.overdue' },
    ];

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogService
     * @param downloader
     * @param filterFormSchema
     * @param repository
     */
    constructor(appContainer, dialogService, downloader, filterFormSchema, repository) {
        super(appContainer);

        this.dialogService    = dialogService;
        this.downloader       = downloader;
        this.filterFormSchema = filterFormSchema;
        this.repository       = repository;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'sealings.requests.manage',
            'sealings.requests.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.filterModel  = this.filterFormSchema.model(this);
        this.filterSchema = this.filterFormSchema.schema(this);

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            resource:        'resource.sealings.sealing',
            repository:      this.repository,
            show:            null,
            edit:            null,
            destroy:         null,
            destroySelected: false,
            actions:         [
                {
                    icon:    'icon-user-check',
                    tooltip: 'form.button.answer-request',
                    action:  (row) => this.appContainer.router.navigateToRoute('sealings.requests.edit', { id: row.id }),
                    visible: (row) => this.appContainer.authenticatedUser.can(['sealings.requests.manage', 'sealings.requests.edit'])
                        && (
                            this.appContainer.authenticatedUser.belongsToControllingEntity()
                            || this.appContainer.authenticatedUser.belongsToManagingEntity()
                        ),
                },
                {
                    icon:    'icon-cross2',
                    tooltip: 'form.button.cancel',
                    action:  (row) => this.cancelRequest(row),
                    visible: (row) => this.appContainer.authenticatedUser.can(['sealings.requests.manage', 'sealings.requests.delete'])
                        && (
                            this.appContainer.authenticatedUser.belongsToMaintenanceCompany()
                            || this.appContainer.authenticatedUser.belongsToOwner()
                        ),
                },
            ],
            options:         [
                {
                    label:  'form.button.export-to-excel',
                    icon:   'icon-file-excel',
                    action: () => this.exportListing('selagens'),
                },
            ],
            buttons:         [],
            selectable:      false,
            sorting:         {
                column:    5,
                direction: 'asc',
            },
            columns:         [
                {
                    data:   'code',
                    name:   'lifts.code',
                    title:  'form.field.lift-number',
                    type:   'link',
                    action: (row) => this.appContainer.router.navigateToRoute('lifts.lifts.view', { id: row.lift_id }),
                },
                {
                    data:  'address',
                    name:  'lifts.address',
                    title: 'form.field.address',
                },
                {
                    data:  'parish',
                    name:  'parishes.name',
                    title: 'form.field.parish',
                },
                {
                    data:  'maintenance_company',
                    name:  'maintenance_company_data.name',
                    title: 'form.field.emie',
                },
                {
                    data:  'owner',
                    name:  'owner_data.name',
                    title: 'form.field.owner',
                },
                {
                    data:            'created_at',
                    name:            'procedures.created_at',
                    title:           'form.field.registered-at',
                    valueConverters: [
                        { name: 'dateFormat' },
                    ],
                },
                {
                    data:            'certificate_deadline',
                    name:            'lift_certificates.deadline',
                    title:           'form.field.certificate-deadline',
                    type:            'label',
                    color:           (row) => this.processDeadlineColor(row.certificate_deadline),
                    valueConverters: [
                        { name: 'dateFormat' },
                    ],
                },
            ],
        };
    }

    /**
     * Returns the correct stoplight based on the current date and deadline
     *
     * @param date
     *
     * @return {string}
     */
    processDeadlineColor(date) {
        let deadline        = moment(date);
        let closeToDeadline = moment(date).subtract(1, 'month');
        let now             = moment();

        if (now.isAfter(deadline, 'day')) {
            return 'badge-danger';
        } else if (now.isAfter(closeToDeadline, 'day')) {
            return 'badge-warning';
        }

        return 'badge-success';
    }

    /**
     * Handles the `cancel` action button
     *
     * @param row
     *
     * @returns {*}
     */
    cancelRequest(row) {
        this.dialogService
            .open({
                viewModel: ConfirmSealingRequestCancellationDialog,
                model: {
                    resource: this.datatable.resource,
                    action:   {
                        method:     this.repository.cancel.bind(this.repository),
                        parameters: [row.id],
                    },
                },
            })
            .whenClosed(response => {
                if ( ! response.wasCancelled) {
                    this.datatable.instance.reload();
                }
            });
    }

}
