import { bindable, inject }              from 'aurelia-framework';
import { AppContainer }                  from 'resources/services/app-container';
import { BaseListViewModel }             from 'base-list-view-model';
import { DialogService }                 from 'aurelia-dialog';
import { Downloader }                    from 'resources/services/downloader';
import { FilterFormSchema }              from 'modules/sealings/in-execution/filter-form-schema';
import { SealingsInExecutionRepository } from 'modules/sealings/in-execution/services/repository';
import moment                            from 'moment';

@inject(AppContainer, DialogService, Downloader, FilterFormSchema, SealingsInExecutionRepository)
export class ListSealingsInExecution extends BaseListViewModel {

    listingId = 'sealings-in-execution-listing';

    @bindable headerTitle = 'listing.sealings.in-execution';
    @bindable repository;
    @bindable datatable;
    @bindable legend = [
        { color: 'success', name: 'messages.on-time' },
        { color: 'warning', name: 'messages.less-than-one-week-remaining' },
        { color: 'danger', name: 'messages.overdue' },
    ];

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogService
     * @param downloader
     * @param filterFormSchema
     * @param repository
     */
    constructor(appContainer, dialogService, downloader, filterFormSchema, repository) {
        super(appContainer);

        this.dialogService    = dialogService;
        this.downloader       = downloader;
        this.filterFormSchema = filterFormSchema;
        this.repository       = repository;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'sealings.in-execution.manage',
            'sealings.in-execution.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.filterModel  = this.filterFormSchema.model(this);
        this.filterSchema = this.filterFormSchema.schema(this);

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            resource:        'resource.sealings.sealing',
            repository:      this.repository,
            show:            null,
            edit:            null,
            destroy:         null,
            destroySelected: false,
            actions:         [],
            options:         [
                {
                    label:  'form.button.export-to-excel',
                    icon:   'icon-file-excel',
                    action: () => this.exportListing('selagens'),
                },
            ],
            buttons:         [],
            selectable:      false,
            sorting:         {
                column:    7,
                direction: 'asc',
            },
            columns:         [
                {
                    data:   'code',
                    name:   'lifts.code',
                    title:  'form.field.lift-number',
                    type:   'link',
                    action: (row) => this.appContainer.router.navigateToRoute('lifts.lifts.view', { id: row.lift_id }),
                },
                {
                    data:  'address',
                    name:  'lifts.address',
                    title: 'form.field.address',
                },
                {
                    data:  'parish',
                    name:  'parishes.name',
                    title: 'form.field.parish',
                },
                {
                    data:  'maintenance_company',
                    name:  'maintenance_company_data.name',
                    title: 'form.field.emie',
                },
                {
                    data:  'owner',
                    name:  'owner_data.name',
                    title: 'form.field.owner',
                },
                {
                    data:  'inspecting_entity',
                    name:  'inspecting_entity_data.name',
                    title: 'form.field.eiie',
                },
                {
                    data:  'maintenance_technician',
                    name:  'maintenance_technician_data.name',
                    title: 'form.field.maintenance-technician',
                },
                {
                    data:            'scheduled_date',
                    name:            'procedures.scheduled_date',
                    title:           'form.field.scheduled-date',
                    type:            'label',
                    color:           (row) => this.processDeadlineColor(row.scheduled_date),
                    valueConverters: [
                        { name: 'dateTimeFormat' },
                    ],
                },
                {
                    data:            'certificate_deadline',
                    name:            'lift_certificates.deadline',
                    title:           'form.field.certificate-deadline',
                    type:            'label',
                    color:           (row) => this.processDeadlineColor(row.certificate_deadline),
                    valueConverters: [
                        { name: 'dateFormat' },
                    ],
                },
            ],
        };
    }

    /**
     * Returns the correct stoplight based on the current date and deadline
     *
     * @param date
     *
     * @return {string}
     */
    processDeadlineColor(date) {
        let deadline        = moment(date);
        let closeToDeadline = moment(date).subtract(1, 'week');
        let now             = moment();

        if (now.isAfter(deadline, 'day')) {
            return 'badge-danger';
        } else if (now.isAfter(closeToDeadline, 'day')) {
            return 'badge-warning';
        }

        return 'badge-success';
    }

}
