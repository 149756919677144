import { bindable, inject }             from 'aurelia-framework';
import { AppContainer }                 from 'resources/services/app-container';
import { BaseFormViewModel }            from 'base-form-view-model';
import { DialogService }                from 'aurelia-dialog';
import { FormSchema }                   from 'modules/sealings/scheduling/edit/form-schema';
import { ScheduleProcedureDialog }      from 'resources/elements/html-elements/dialogs/schedule-procedure-dialog';
import { SealingsSchedulingRepository } from 'modules/sealings/scheduling/services/repository';

@inject(AppContainer, DialogService, FormSchema, SealingsSchedulingRepository)
export class ScheduleSealing extends BaseFormViewModel {

    @bindable headerTitle = 'form.title.schedule-sealing';
    @bindable formId      = 'sealings-scheduling-edition-form';

    alert  = {};
    model  = {};
    schema = {};

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogService
     * @param formSchema
     * @param repository
     */
    constructor(appContainer, dialogService, formSchema, repository) {
        super(appContainer);

        this.dialogService = dialogService;
        this.formSchema    = formSchema;
        this.repository    = repository;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/sealings/scheduling/form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'sealings.scheduling.manage',
            'sealings.scheduling.edit',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params, navigationInstruction) {
        super.activate(navigationInstruction);

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.fetchData(params).then(() => this.schema = this.formSchema.schema(this));
    }

    /**
     * Handles attached event
     */
    attached() {
        super.attached();

        this.headerSubtitle = this.model.code;
    }

    /**
     * Fetches data from server
     *
     * @param {id}
     *
     * @returns {Promise.<*>|Promise}
     */
    fetchData({ id }) {
        return this.repository.find(id).then((response) => {
            this.initialModel.assign(response);
            this.model.assign(response);
        });
    }

    /**
     * Submits view form
     */
    submit() {
        this.alert = null;

        this.dialogService
            .open({
                viewModel: ScheduleProcedureDialog,
                model:     {
                    body: 'dialog.body.schedule-procedure',
                    type: 'resource.sealings.sealing',
                },
            })
            .whenClosed(response => {
                if ( ! response.wasCancelled) {
                    return this.repository
                        .schedule(this.model.id, this.model)
                        .then(httpResponse => this.afterSubmitCallback(httpResponse))
                        .then(httpResponse => httpResponse.status === true ? this.appContainer.router.navigateToRoute('sealings.scheduling.index') : false);
                }
            });

        return false;
    }

    /**
     * Handles the submit form response
     *
     * @param response
     */
    afterSubmitCallback(response) {
        if (response.status === true) {
            this.appContainer.notifier.successNotice(response.message);

            this.initialModel.assign(this.model);
        } else {
            this.alert = this.alertMessage(response.status, response.message, response.errors);
        }

        return response;
    }

}
